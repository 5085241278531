import Vue from "vue";
import Vuex from "vuex";

import { user } from "./modules/user";
import { carlist } from "./modules/carlist";
import { partlist } from "./modules/partlist";
import { dashboard } from "./modules/dashboard";
import { makeModelYear } from "./modules/makeModelYear";
import { countryDetails } from "./modules/countryDetails";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    drawer: null
  },
  getters: {
    getLoaderStatus(state) {
      return state.loading;
    },
    drawer(state) {
      return state.drawer;
    }
  },
  mutations: {
    setLoader(state, status) {
      state.loading = status;
    },
    setDrawer(state, status) {
      state.drawer = status;
    }
  },
  actions: {},
  modules: {
    user,
    carlist,
    partlist,
    dashboard,
    makeModelYear,
    countryDetails
  }
});
