import makeModelYearService from "@/services/makeModelYear.service";

const state = {
  trimList: [],
  years: [],
  makeList: [],
  modelList: [],
  makeModelList: [],
};

const actions = {
  getMakes({ commit }, payload) {
    return makeModelYearService.getMakes(commit, payload);
  },

  getModels({ commit }, payload) {
    return makeModelYearService.getModels(commit, payload);
  },
  
  getMakesModels({ commit }, payload) {
    return makeModelYearService.getMakesModels(commit, payload);
  },

  getYears({ commit }, payload) {
    return makeModelYearService.getYears(commit, payload);
  },

  getTrims({ commit }, payload) {
    return makeModelYearService.getTrims(commit, payload);
  },
};

const mutations = {
  setMakes(state, response) {
    state.makeList = response.data;
  },

  setModels(state, response) {
    state.modelList = response.data;
  },

  setMakesModels(state, response) {
    state.makeModelList = response.data;
  },

  setYears(state, response) {
    state.years = response.data;
  },

  setTrims(state, response) {
    state.trimList = response.data;
  },
};

const getters = {
  getMakes(state) {
    return state.makeList;
  },

  getModels(state) {
    return state.modelList;
  },

  getMakesModels(state) {
    return state.makeModelList;
  },

  getYears(state) {
    return state.years;
  },

  getTrims(state) {
    return state.trimList;
  },
};

export const makeModelYear = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
